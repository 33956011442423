
// this needs to be converted over to use the row/col system in chota
export default {
	name: "CoreBlockColumns",
	props: {
		index: {
			type: Number,
			default: 0,
		},
		nestedLevel: {
			type: Number,
			default: 0,
		},
		settings: {
			type: Object,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		bodyClasses() {
			const classList = ["block__body"];
			if (!this.hasChildren) {
				classList.push("block__body--empty");
			}
			return classList;
		},
		blockChildren() {
			return this.settings?.blocks || [];
		},
		blockChildrenByColumnCount() {
			const countDiff = this.columnCount - this.blockChildrenCount;
			if (countDiff === 0) {
				return this.blockChildren;
			}
			const adjustedChildren = [...this.blockChildren];
			if (countDiff < 0) {
				adjustedChildren.length = this.columnCount;
			}
			if (countDiff > 0) {
				for (let i = 0; i < countDiff; i++) {
					adjustedChildren.push({
						type: "rows",
						id: `${this.blockId}_c${i}`, // assures this is a unique id
						blocks: [
							{
								type: null,
								id: `${this.blockId}_c${i}_0`, // assures this is a unique id
							},
						],
					});
				}
			}
			return adjustedChildren;
		},
		blockChildrenCount() {
			return this.blockChildren.length;
		},
		blockId() {
			return this.settings.id;
		},
		blockVariantLayout() {
			return this.settings?.variants?.layout || "rows";
		},
		columnCount() {
			let count = this.settings["column-count"];
			if (count < 2) {
				count = 2;
			}
			if (count > 6) {
				count = 6;
			}
			return count;
		},
		nestedLevelNext() {
			return this.nestedLevel + 1;
		},
		sectionMainClasses() {
			const classList = ["block__container block__section_main"];
			let columnCount = this.settings["column-count"];
			if (columnCount < 2) {
				columnCount = 2;
			}
			if (columnCount > 6) {
				columnCount = 6;
			}
			classList.push(`block__section_main--${columnCount}_column`);
			return classList;
		},
		/*
			todo the childBlockComponent property is only useful for the builder
			so it should be separated from this block's logic
		*/
		childBlockComponent() {
			if (this.isEditable) {
				return "ForgeControlBlock";
			}
			return "CoreBlock";
		},
	},
};
